import * as React from "react"
import * as styles from "./ui.css"

interface MapProps {
  locationQuery: string
}
export default function Map({ locationQuery }: MapProps) {
  return (
    <div className={styles.mapRouter}>
      <div className={styles.googleMapCanvas}>
        <iframe
          width="300"
          height="300"
          loading="lazy"
          id="gmap_canvas"
          src={`https://maps.google.com/maps?q=${locationQuery}&t=&z=17&ie=UTF8&iwloc=&output=embed&key=AIzaSyBWmst57iihOcCIfEQEdYZnFtFQvcNKBB4`}
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
    </div>
  )
}
