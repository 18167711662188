import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Map from "../components/map"

import { Container, Box, Heading } from "../components/ui"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

interface PageProps {
  data: {
    page: {
      id: string
      title: string
      slug: string
      description: string
      image: { id: string; alt: string; gatsbyImageData: IGatsbyImageData }
      html: string
    }
  }
}

export default function Page(props: PageProps) {
  const { page } = props.data

  return (
    <Layout {...page}>
      <Box paddingY={4}>
        <Container>
          <Heading as="h1">{page.title}</Heading>
          {page.image && (
            <Box center>
              <GatsbyImage
                alt={page.image.alt}
                image={page.image.gatsbyImageData}
              />
            </Box>
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: page.html,
            }}
          />
          {
            // @todo remove this hack and move this to dedicated contact page
            page.title === "Contact" && (
              <Map locationQuery="shams%20dental%20jumeirah" />
            )
          }
        </Container>
      </Box>
    </Layout>
  )
}
export const query = graphql`
  query PageContent($id: String!) {
    page(id: { eq: $id }) {
      id
      title
      slug
      description
      image {
        id
        alt
        gatsbyImageData
      }
      html
    }
  }
`
